import { defineStore } from "pinia";
import { ref, watch } from "vue";
import useWalletStore from "./WalletStore";

const NEONZ_CONTRACT_ADDR = 'KT1MsdyBSAMQwzvDH4jt2mxUKJvBSWZuPoRJ';
const LIGHTFIELDZ_CONTRACT_ADDR = 'KT1FkMRSsrqWXU88PEUuPsjuL9hSEq23R1vD';

export interface INeonzToken {
  contract: string,
  network: string,
  token_id: number,
  symbol: string,
  name: string,
  decimals: number,
  description: string,
  artifact_uri: string,
  display_uri: string,
  thumbnail_uri: string,
  is_transferable: true,
  is_boolean_amount: true,
  creators: [
    string,
  ],
  formats: [
    {
      dimensions: {
        unit: string,
        value: string,
      },
      fileSize: number,
      mimeType: string,
      uri: string,
    },
    {
      dimensions: {
        unit: string,
        value: string,
      },
      fileSize: number,
      mimeType: string,
      uri: string,
    }
  ],
  balance: string
}

const useNeonzStore = defineStore('neonz', () => {
  const neonz = ref([] as INeonzToken[]);
  const lightfieldz = ref([] as INeonzToken[]);

  const WalletStore = useWalletStore();
  watch(() => WalletStore.userAddress, addr => {
    if (addr) {
      useNeonzStore().fetchNeonz();
      useNeonzStore().fetchLightfieldz();
    }
  })

  const fetchNeonz = async () => {
    neonz.value = [];
    const addr = WalletStore.userAddress;
    if (!addr) throw new Error('Wallet not logged in.')

    let moreToFetch = true;
    const pageSize = 50;
    let offset = 0;
    while(moreToFetch) {
      const response = await fetch(`https://api.better-call.dev/v1/account/mainnet/${addr}/token_balances?contract=${NEONZ_CONTRACT_ADDR}&hide_empty=true&size=${pageSize}&offset=${offset}`, {
        method: 'GET',
      })
      const data = await response.json();

      neonz.value.push(...data.balances as INeonzToken[]);
      
      const { total } = data;
      moreToFetch = offset + pageSize < total;
      offset += pageSize;
    }
  }

  const fetchLightfieldz = async () => {
    neonz.value = [];
    const addr = WalletStore.userAddress;
    if (!addr) throw new Error('Wallet not logged in.')

    let moreToFetch = true;
    const pageSize = 50;
    let offset = 0;
    while(moreToFetch) {
      const response = await fetch(`https://api.better-call.dev/v1/account/mainnet/${addr}/token_balances?contract=${LIGHTFIELDZ_CONTRACT_ADDR}&hide_empty=true&size=${pageSize}&offset=${offset}`, {
        method: 'GET',
      })
      const data = await response.json();

      lightfieldz.value.push(...data.balances as INeonzToken[]);
      
      const { total } = data;
      moreToFetch = offset + pageSize < total;
      offset += pageSize;
    }
  }
  return {
    neonz,
    lightfieldz,
    fetchNeonz,
    fetchLightfieldz,
  }
})


export default useNeonzStore;
